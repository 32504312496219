import { PoolConfig, QuoteToken, PoolCategory } from './types'
import contracts from './contracts'

const pools: PoolConfig[] = [
  {
    sousId: 5,
    poolName: 'BISON',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BISON,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x3d5e1C77e9C81B72b0b0F88Cd63d2Aab38881b96',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink:
      'https://app.apeswap.finance/swap?inputCurrency=ETH&outputCurrency=0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    harvest: true,
    tokenPerBlock: '0.006751543209876540',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 2,
    poolName: 'BISON-BNB APE',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BISONBNB,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0xec1214ee197304c17eb9e427e246a4fd37ba718e',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x99AeB03aCEBAF7b0742a67cE0f3C49E82F5f4897',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://app.apeswap.finance/add/ETH/0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    harvest: true,
    tokenPerBlock: '0.014467592592592600',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 3,
    poolName: 'BISON-BNB PCS',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BISONBNB,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0xe5da89fc07cbd30bfc92e14bdbe4c6156d309d12',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x26DdcC2DA40c7e0a85b2396B9d46b7a36C5295b2',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://pancakeswap.finance/add/BNB/0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    harvest: true,
    tokenPerBlock: '0.014467592592592600',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 4,
    poolName: 'biAPE-BNB APE',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biAPEBNB,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0x5035a262543f5ca8502e38a5a55b91a1a26b4f9c',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x7aB0BEd80042bCa202dA306F7C58C4c8a0b2fF27',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://app.apeswap.finance/add/ETH/0xf19b6746769132926ee82f47137aeb7a8702c4b9',
    harvest: true,
    tokenPerBlock: '0.014467592592592600',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 6,
    poolName: 'biCHAIN-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biCHAINBNB,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0xDAff7BEB8cAD0E75CcA3d9F36b8f8C0e1cf37b85',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0xdBf6Afe135e2C4CB599680C2F1554bBDa219CE10',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/BNB/0x10A520829C1E9631Ef9E60e37eEA3916092cAa99',
    harvest: true,
    tokenPerBlock: '0.007440476190476190',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 7,
    poolName: 'BISON-BUSD ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BUSDBISON,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0x377bc90dd62c891d30d2154f4e9c0aebd75443e3',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0xdfeE4abb0bd61088D2E97dccCF8144617770c46D',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink:
      'https://arbex.bishares.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    harvest: true,
    tokenPerBlock: '0.01527777778',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 8,
    poolName: 'biGAME-BUSD',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biGameBUSD,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0xc8c3564d8c81273e1f6fe9945c0019131c0ade20',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x28C6554Eae70DcdA2A2758924cAa63f3eBB1F21D',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink:
      'https://arbex.bishares.finance/add/0x8F9f6589C2eB04Ae4A0A737b78353FdBB628ffe8/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    harvest: true,
    tokenPerBlock: '0.006944444444',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 10,
    poolName: 'BISON',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BISON,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x0B5331eEE5E2f48D1faFd67C55978D18dC8AD4B5',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/swap',
    harvest: true,
    tokenPerBlock: '0.008126592812',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 9,
    poolName: 'BISON-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BNBBISON,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0x8d289b320926b639915c1f623c74eb369cb99f63',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x31DaF03DAbC03f78820062121b776196e86a66eF',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0x19A6Da6e382b85F827088092a3DBe864d9cCba73/BNB',
    harvest: true,
    tokenPerBlock: '0.01787850419',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 11,
    poolName: 'biMEX-BUSD ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biMEXBUSD,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0xabe2cbd7bd23639f4d1ad9f7c29f53d21ec26f1b',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x7373711C9131eA933fCAde6b22F7Ad85C7f77551',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0xFC02D63E44e71D4b8C81a436f19Ce580Eb7bFAda/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    harvest: true,
    tokenPerBlock: '0.00462962963',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 12,
    poolName: 'biDEFI-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biDEFIBNB,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x5c22a51c5a5647b7d2f8164cecb5ea1398633a72',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x44aea582EFE2c7F1DF6ddcBdeb2a713d498cBaD2',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0x28939911a862bd4E6b10E281F233a5E060114E0B/BNB',
    harvest: true,
    tokenPerBlock: '0.002314814815',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 13,
    poolName: 'biFOMO-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biFOMOBNB,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0xb773b31ee0ed5a6e32e51c1af39fb943e4288d28',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x8212834E0d8788B0a9De1df5737fAAbEf3452039',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0xf09026225AA59D8Ab89Ec4E06aa1d6dCbe5eB1cF/BNB',
    harvest: true,
    tokenPerBlock: '0.002314814815',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 14,
    poolName: 'biCHAIN-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.biCHAINBNB,
    earningToken: '0x19a6da6e382b85f827088092a3dbe864d9ccba73',
    stakingTokenAddress: '0xDAff7BEB8cAD0E75CcA3d9F36b8f8C0e1cf37b85',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0xCD32AbC63BbA1D47279Df068B49babc80777106a',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/BNB/0x10A520829C1E9631Ef9E60e37eEA3916092cAa99',
    harvest: true,
    tokenPerBlock: '0.002314814814814810',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 15,
    poolName: 'BISON',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BISON,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x5963Df2e4E65435d1C75b2339de8Ee1Cb5656633',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/swap',
    harvest: true,
    tokenPerBlock: '0.002314814815',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 16,
    poolName: 'BISON-BNB ARBX',
    tokenName: 'BUSD',
    stakingTokenName: QuoteToken.BNBBISON,
    earningToken: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    stakingTokenAddress: '0x8d289b320926b639915c1f623c74eb369cb99f63',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0xe04445758d951BFeCD3f4d738b73eA2393977e6E',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0x19A6Da6e382b85F827088092a3DBe864d9cCba73/BNB',
    harvest: true,
    tokenPerBlock: '0.03472222222',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 17,
    poolName: 'BISON-BNB ARBX',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.BNBBISON,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x8d289b320926b639915c1f623c74eb369cb99f63',
    contractAddress: {
      97: '0xEA93812aA70eC89932aF6EBe6eCe93135712e4Ab',
      56: '0x2Ad8Ea78A946Cc9169EF2B85F7d2544B029D7991',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://arbex.bishares.finance/add/0x19A6Da6e382b85F827088092a3DBe864d9cCba73/BNB',
    harvest: true,
    tokenPerBlock: '0.008680555555555556',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
  {
    sousId: 18,
    poolName: 'ybfDEX BOOSTER',
    tokenName: 'BISON',
    stakingTokenName: QuoteToken.ybfDEX,
    earningToken: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
    stakingTokenAddress: '0x0ce62dc7d1a0414d6aec2ede849de79eeba7544f',
    contractAddress: {
      97: '0x65114d5af1a33f89d36a79a56c75a4728e02d492',
      56: '0x109E1d51976ACD3E34156B08b0B0DbC849857649',
    },
    poolCategory: PoolCategory.REWARDS,
    projectLink: 'https://app.bishares.finance/#/ybf/0x0ce62DC7d1A0414D6aeC2Ede849dE79eEbA7544f',
    harvest: true,
    tokenPerBlock: '0.008680555555555556',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
    quoteTokenSymbol: QuoteToken.BISON,
    quoteTokenAdresses: contracts.cake,
  },
]

export default pools
