import BigNumber from 'bignumber.js'

export const BSC_SCAN_URL = 'https://bscscan.com/address/'
export const CHAIN_ID = 56
export const RPC_URL_56 = 'https://bsc-dataseed1.binance.org'
export const POLLING_INTERVAL = 12000
export const BLOCKS_PER_YEAR = new BigNumber(10512000)

export const WBNB_ADDRESS = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
export const BUSD_ADDRESS = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'
